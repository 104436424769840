import { combineReducers } from "@reduxjs/toolkit"

import payment from "./payment"
import product from "./product"
import account from "./individualAccount"
import tax from "./tax"
import coupon from "./coupon"
import reprice from "./reprice"
import subscription from "./subscription"
import billingInfo from "./creditcard"
import prorate from "./network/prorate"
import validateEmail from "./guest/validateEmail"
import login from "./network/login"
import networkPayment from "./network/payment"
import validateOrgDiscount from "./guest/validateOrgDiscount";
import validateNomaEmail from "./guest/validateNomaEmail";
import members from './members'


const rootReducer = combineReducers({
  payment: payment.reducer,
  product: product.reducer,
  account: account.reducer,
  tax: tax.reducer,
  coupon: coupon.reducer,
  reprice: reprice.reducer,
  subscription: subscription.reducer,
  billingInfo: billingInfo.reducer,
  prorate: prorate.reducer,
  validateEmail: validateEmail.reducer,
  validateOrgDiscount: validateOrgDiscount.reducer,
  login: login.reducer,
  networkPayment: networkPayment.reducer,
  validateNomaEmail: validateNomaEmail.reducer,
  member: members.reducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
