import {Dialog, Divider} from "@mui/material";
import {NoteContainer, NoteContentContaineer, NoteTitleContainer, StyledDialog} from "./styles";
import {Button} from "../Button";
import MarcTeer from "../../images/icons/MarcTeer.png"
import MarcTeerSignature from "../../images/icons/MarcTeerSignature.png"
import React from "react";

interface CeoNoteDialogProps{
    isOpen: boolean;
    onSubmit: () => void;
}

export const CeoNoteDialog = (props: CeoNoteDialogProps) => {
    return <StyledDialog
        open={props.isOpen}
    >
        <NoteContainer>
            <NoteTitleContainer>
                <img className="closeBox" src={MarcTeer} alt="" />
                <span>A Heartfelt Thank You from Marc Teer, CEO</span>
            </NoteTitleContainer>
            <Divider/>
            <NoteContentContaineer>
                <p>
                    Thank you for becoming a valued customer!
                    <br/>
                    <br/>
                    As an independent company, we are entirely funded by customers like you. Your support makes Black Spectacles possible.
                    <br/>
                    <br/>
                    For over a decade, we've dedicated ourselves to providing top-tier resources that educate and inspire architects to thrive in their careers. We're honored to be part of your journey.
                    <br/>
                    <br/>
                    If you ever need assistance, please reach out to me directly at marc@blackspectacles.com. I'm here to help.
                    <br/>
                    <br/>
                    Thanks again, and all the best,
                    <br/>
                </p>
                <img width="100px" height="30px" src={MarcTeerSignature} alt="" />
                <br/>
                CEO and Founder, Black Spectacles
            </NoteContentContaineer>
            <Button
                onClick={() => props.onSubmit()}
                color="primary"
                variant="contained"
            >
                Get Started!
            </Button>
        </NoteContainer>

    </StyledDialog>
}
