import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import store from "./redux/configureStore"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./styles/theme"
import { BrowserRouter as Router } from "react-router-dom"
import { IntercomProvider } from "react-use-intercom"
import { datadogRum } from "@datadog/browser-rum"

import "./index.css"
import "./fonts/FreightDispProBook-regular.woff2"
import "./fonts/FreightDispProBook-regular.woff"
import "./fonts/ABCWhyte-Bold.woff2"
import "./fonts/ABCWhyte-Bold.woff"
import "./fonts/ABCWhyte-Regular.woff2"
import "./fonts/ABCWhyte-Regular.woff"
import "./fonts/FreightDispProBook-regular.woff"
import "./fonts/Georgia.woff"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { AuthProvider } from "./providers/AuthProvider"
import { Auth0Provider } from "@auth0/auth0-react"

if (
  process.env.REACT_APP_DATADOG_UI_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_UI_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_UI_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_UI_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "bks-checkout",
    env: process.env.REACT_APP_ENV_NAME,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  })
}

const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID || "rm29b11q"

const disabledIntercomURLS = ["/updateBilling", "/displayBillingInfo"]

const bootIntercom = !disabledIntercomURLS.includes(window.location.pathname)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <Auth0Provider
      domain="dev-k9591ja0.us.auth0.com"
      clientId="M0CBd1aK1xGYTsMfCv1s8mkceSSk1QEl"
      authorizationParams={{
        audience: 'https://services.blackspectacles.com/',
        redirect_uri: window.location.origin,
      }}
    >
    <AuthProvider>
      <IntercomProvider appId={intercomAppId} autoBoot={bootIntercom}>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </IntercomProvider>
    </AuthProvider>
    </Auth0Provider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
