import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { setCookie } from "../utils/cookies"
import { AuthContextInterface } from "../providers/AuthProvider"
import { toast } from "react-toastify"

export type AdminAccountDetails = {
  firstName: string
  lastName: string
  firmName: string
  workEmail: string
  password: string
  adminIsUser: boolean
  firmDomain?: string
}

export type Member = {
  memberId?: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  adminAccountDetails?: AdminAccountDetails
  member?: Member
  slug?: string
  status: LoadingStatuses
  error?: string
}

type CreateAccountPayload = {
  adminAccountDetails: AdminAccountDetails
}

type CreateFirm = {
  organization: string,
  property_id: number,
  isUserAdmin: boolean,
  auth: AuthContextInterface
}

export const createNewAccount = createAsyncThunk<any, CreateAccountPayload>(
  "/createAccount",
  async ({ adminAccountDetails }) => {
    const { firstName, lastName, firmName, workEmail, password, adminIsUser } =
      adminAccountDetails

    const fields = {
      email: workEmail,
      password: password,
      firstname: firstName,
      lastname: lastName,
      organization: firmName,
      property_id: 1,
      admin_is_user: adminIsUser,
    }

    return useApi(null, "/v1/guest/are/group", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

export const createFirm = createAsyncThunk<any, CreateFirm>(
  "/createFirm",
  async (createFirm) => {
    const { organization, isUserAdmin, auth } =
      createFirm

    const fields = {
      organization: organization,
      property_id: 1,
      admin_is_user: isUserAdmin,
    }

    return useApi(auth, "/v1/network/group", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

const initialState: SliceState = {
  adminAccountDetails: undefined,
  member: undefined,
  slug: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewAccount.fulfilled, (state, action) => {
        state.adminAccountDetails = action.payload
        state.member = { memberId: action.payload.member.id }
        state.slug = action.payload.slug
        state.status = LoadingStatuses.Succeeded
        setCookie(
          process.env.REACT_APP_TOKEN_NAME || "local_bks_token",
          action.payload.member.auth_token
        )
      })
      .addCase(createNewAccount.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
      .addCase(createFirm.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
        toast.error(action.error.message)
      })
  },
})
