import {Dialog, Typography, Box} from '@mui/material'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const StyledDialog = styled(Dialog)`
  && .MuiDialog-paper {
    border-radius: 0; 
    width: 480px;
    max-width: 480px;
  }
`

export const NoteContainer = styled.div`
  
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px
`

export const NoteTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size:16px;
  line-height: 21px;
  gap:16px;
`

export const NoteContentContaineer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Public Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`


