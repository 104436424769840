import {useEffect} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import Cookies from 'universal-cookie'

const useRefreshAccessToken = () => {
  const {isAuthenticated, getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(token => {
        setRefreshAccessToken(token)
      })

      const intervalId = setInterval(() => {
        getAccessTokenSilently().then(token => {
          setRefreshAccessToken(token)
        })
      }, 60000)

      return () => clearInterval(intervalId)
    }
  }, [isAuthenticated])
}

export const setRefreshAccessToken = (token: string) => {
  const cookies = new Cookies()

  cookies.set(process.env.REACT_APP_TOKEN_NAME || 'local_bks_token', token, {
    path: '/',
    domain:
      process.env.REACT_APP_TOKEN_NAME === 'local_bks_token'
        ? 'localhost'
        : 'blackspectacles.com',
  })
}

export default useRefreshAccessToken
