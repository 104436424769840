import React, { useState, useCallback } from "react"
import { Box, Typography } from "@mui/material"
import TextField from "../TextField"
import { Button } from "../Button"
import {
  FormContainer,
  FormHeader,
  FormFieldsBox,
  FormRow,
  FieldNote,
  FormFooter,
  BlueLabel,
  CloseButton,
  ServerErrorMessage,
} from "./styles"
import CloseBox from "../../images/icons/CloseBox.png"
import * as Yup from "yup"
import update from "immutability-helper"
import { useAppDispatch } from "../../redux/configureStore"
import { AdminAccountDetails, createNewAccount } from "../../redux/account"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import { useAuth } from "../../providers/AuthProvider"
interface LabelProps {
  text: string
  required?: boolean
}

interface ModalProps {
  modalOpen: boolean
  modalDisabled: () => void
  setPage: (num: number) => void
}

const View = ({ modalOpen, modalDisabled, setPage }: ModalProps) => {
  const auth = useAuth()
  const [open, setOpen] = useState(modalOpen)
  const [isDisabled, setIsDisabled] = useState(true)
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false)

  const [formValues, setFormValues] = useState<AdminAccountDetails>({
    firstName: "",
    lastName: "",
    firmName: "",
    workEmail: "",
    password: "",
    adminIsUser: false,
  })

  const [touched, setTouched] = useState<any>({
    firstName: false,
    lastName: false,
    firmName: false,
    workEmail: false,
    confirmWorkEmail: false,
    password: false,
    confirmPassword: false,
  })

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    firmName: "",
    workEmail: "",
    confirmWorkEmail: "",
    password: "",
    confirmPassword: "",
    serverError: "",
  })

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    firmName: Yup.string().required("Firm name required"),
    workEmail: Yup.string()
      .email("Must be a valid email address.")
      .required("Work email required"),
    confirmWorkEmail: Yup.mixed().test(
      "match",
      "Emails do not match",
      function () {
        return this.parent.workEmail === this.parent.confirmWorkEmail
      }
    ),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]/,
        "Password must contain one uppercase letter, one lowerecase letter, one number, and one special character."
      )
      .min(8, "Password must be at least 8 characters.")
      .required("Password is required"),

    confirmPassword: Yup.mixed().test(
      "match",
      "Passwords do not match",
      function () {
        return this.parent.password === this.parent.confirmPassword
      }
    ),
  })

  const onFieldChange = useCallback(
    (fieldName: string, value: string | boolean, selectInput?: boolean) => {
      // convert input values from string to boolean
      if (selectInput) value = value === "true" ? true : false

      setFormValues((prevValues: AdminAccountDetails) => {
        return update(prevValues, {
          [fieldName]: {
            $set: value,
          },
        })
      })
    },
    []
  )

  const handleSetTouched = (field: string) => {
    setTouched({
      ...touched,
      [field]: true,
    })
  }

  const validateFields = async (submitting?: boolean) => {
    await validationSchema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setFormErrors({
          firstName: "",
          lastName: "",
          firmName: "",
          workEmail: "",
          confirmWorkEmail: "",
          password: "",
          confirmPassword: "",
          serverError: "",
        })
        setIsDisabled(false)
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]:
              touched[error.path] || submitting ? error.message : "",
          }
        }, {})
        setFormErrors(errors)
        setIsDisabled(true)
      })
  }

  const handleClose = (event: object, reason: string) => {
    if (reason === "backdropClick") return
    window.dataLayer.push({ event: "closeForm" })
    setOpen(false)
    modalDisabled()
  }

  const Label = ({ text, required }: LabelProps) => {
    return (
      <>
        <span>{text}</span>
        {required && <span style={{ color: "#3057E1" }}> * </span>}
      </>
    )
  }

  const handleSubmit = async () => {
    window.dataLayer.push({ event: "proceedToBilling" })

    setIsDisabled(true)

    const isFormValid = await validationSchema.isValid(formValues, {
      abortEarly: false, // Prevent aborting validation after first error
    })

    setTouched({
      firstName: true,
      lastName: true,
      firmName: true,
      workEmail: true,
      confirmWorkEmail: true,
      password: true,
      confirmPassword: true,
    })

    setFormErrors({ ...formErrors, serverError: "" })

    if (!isFormValid) {
      await validateFields(true)
      setIsDisabled(true)
      return
    }

    try {
      const { type, error }: any = await dispatch(
        createNewAccount({ adminAccountDetails: formValues })
      )

      if (type === createNewAccount.rejected.type) {
        const responseErrors = {
          emailError: "Email exists. Please use a different email address.",
          serverError: "Server error, please try again.",
        }

        let errorMessage
        if (error.message === "email exists") {
          errorMessage = responseErrors.emailError
        } else {
          errorMessage = responseErrors.serverError
        }

        setFormErrors({
          ...formErrors,
          serverError: errorMessage,
        })

        return
      }

      setOpen(false)
      auth.handleSetIsAuthenticated(true)
      setPage(2)
      window.scrollTo(0, 0)
    } catch (error) {
      return
    }
  }

  const handleRedirect = () => {
    window.dataLayer.push({ event: "signIn" })
    window.location.href = `${process.env.REACT_APP_ARE_URL}`
  }

  return (
    <Box>
      <FormContainer
        open={open}
        onClose={handleClose}
        scroll={"body"}
        maxWidth="md"
        sx={{ "& .MuiDialog-paper": { maxHeight: 700, maxWidth: 900 } }}
      >
        <FormHeader>
          <Typography fontSize={"22px"}>Step 1: Create your account</Typography>
          <CloseButton onClick={(e) => handleClose(e, "closeButton")}>
            <img className="closeBox" src={CloseBox} alt="" />
          </CloseButton>
        </FormHeader>

        <FormFieldsBox dividers>
          <Typography variant="body1" className="already-have-an-account">
            Already have an account?{" "}
            <BlueLabel onClick={handleRedirect}>Sign in.</BlueLabel>
          </Typography>

          <form method={"POST"}>
            <FormRow className="formRowSpacing">
              <TextField
                label={<Label text="First name" required={true} />}
                name={"firstName"}
                variant={"filled"}
                placeholder="John"
                fullWidth={true}
                onChange={(e) => onFieldChange("firstName", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.firstName}
                onFocus={() => {
                  handleSetTouched("firstName")
                }}
              />

              <TextField
                label={<Label text="Last name" required={true} />}
                name={"lastName"}
                variant={"filled"}
                placeholder="Smith"
                fullWidth={true}
                onChange={(e) => onFieldChange("lastName", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.lastName}
                onFocus={() => {
                  handleSetTouched("lastName")
                }}
              />
            </FormRow>
            <FormRow className="formRowSpacing">
              <TextField
                label={<Label text="Firm name" required={true} />}
                name={"firmName"}
                variant={"filled"}
                placeholder="E.g. ABC"
                fullWidth={true}
                onChange={(e) => onFieldChange("firmName", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.firmName}
                onFocus={() => {
                  handleSetTouched("firmName")
                }}
              />
            </FormRow>

            <FormRow className="formRowSpacing">
              <TextField
                label={<Label text="Work email" required={true} />}
                name={"workEmail"}
                variant={"filled"}
                placeholder="John.doe@example.com"
                fullWidth={true}
                onChange={(e) => onFieldChange("workEmail", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.workEmail}
                onFocus={() => {
                  handleSetTouched("workEmail")
                }}
              />
              <TextField
                label={<Label text="Confirm work email" required={true} />}
                name={"confirmWorkEmail"}
                variant={"filled"}
                placeholder="-"
                fullWidth={true}
                onChange={(e) =>
                  onFieldChange("confirmWorkEmail", e.target.value)
                }
                onBlur={() => validateFields()}
                errorMessage={formErrors?.confirmWorkEmail}
                onFocus={() => {
                  handleSetTouched("confirmWorkEmail")
                }}
              />
            </FormRow>

            <FormRow className="formRowSpacing lastRow">
              <TextField
                label={<Label text="Password" required={true} />}
                name={"password"}
                type={showPassword ? "text" : "password"}
                variant={"filled"}
                placeholder="●●●●●●●●"
                fullWidth={true}
                onChange={(e) => onFieldChange("password", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.password}
                onFocus={() => {
                  handleSetTouched("password")
                }}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={<Label text="Confirm password" required={true} />}
                name={"confirmPassword"}
                type={showPassword ? "text" : "password"}
                variant={"filled"}
                placeholder="●●●●●●●●"
                fullWidth={true}
                onChange={(e) =>
                  onFieldChange("confirmPassword", e.target.value)
                }
                onBlur={() => validateFields()}
                errorMessage={formErrors?.confirmPassword}
                onFocus={() => {
                  handleSetTouched("confirmPassword")
                }}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormRow>

            <FieldNote>
              <Typography variant="body2">
                Password must be at least 8 characters, contain one uppercase
                character, one lowercase character, one number, and one special
                character '!@#$%&*'.
              </Typography>
            </FieldNote>
          </form>
        </FormFieldsBox>
        <ServerErrorMessage>
          <Typography variant="body1">{formErrors.serverError}</Typography>
        </ServerErrorMessage>

        <FormFooter>
          <Button
            color={"primary"}
            children={"Proceed to billing"}
            size={"large"}
            onClick={handleSubmit}
            disabled={isDisabled}
          />
        </FormFooter>
      </FormContainer>
    </Box>
  )
}

export default View
