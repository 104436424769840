import Cookies from "universal-cookie"

const cookies = new Cookies()

const today = new Date()
const nextWeek = new Date()
nextWeek.setDate(today.getDate() + 7)

export const getCookie = (name: string) => {
  return cookies.get(name)
}

export const removeCookie = (name: string) => {
  cookies.remove(name, {
    path: "/",
    domain:
      process.env.REACT_APP_ENV_NAME === "local"
        ? "localhost"
        : "blackspectacles.com",
  })
}

export const setCookie = (name: string, value: string) => {
  cookies.set(name, value, {
    path: "/",
    domain:
      process.env.REACT_APP_ENV_NAME === "local"
        ? "localhost"
        : "blackspectacles.com",
    expires: nextWeek,
  })
}
